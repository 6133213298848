.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
  filter: blur(5px);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  filter: blur(0);

  transition: opacity 250ms ease-out, transform 300ms ease, filter 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
  filter: blur(0);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);
  filter: blur(5px);

  transition: opacity 250ms ease-out, transform 300ms ease, filter 300ms ease;
}