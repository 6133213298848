.work-order {
  .btn-cancel-answer {
    max-width: 50px;
  }
  .comments {
    overflow: auto;
    max-height: 100px;
    border: 1px solid #dee2e6;
    white-space: pre-wrap;
    font-size: 0.75em;
    position: relative;
    z-index: 0;
    padding: 0 10px 15px;
    ~ .comments-shadow {
      background: linear-gradient(
        to top,
        rgba(251, 251, 251, 1) 0%,
        rgba(251, 251, 251, 0) 100%
      );
      top: 0;
    }
  }
  .comments-shadow {
    display: block;
    height: 10px;

    background: linear-gradient(
      to bottom,
      rgba(251, 251, 251, 1) 0%,
      rgba(251, 251, 251, 0) 100%
    );
    margin-top: -10px;
    top: 10px;
    position: relative;
    z-index: 1;
  }
}

.dark_mode {
  .comments {
    border: 1px solid lighten(#1c1c1c, 10%);
    ~ .comments-shadow {
      background: linear-gradient(
        to top,
        rgba(28, 28, 28, 1) 0%,
        rgba(28, 28, 28, 0) 100%
      );
    }
  }
  .comments-shadow {
    background: linear-gradient(
      to bottom,
      rgba(28, 28, 28, 1) 0%,
      rgba(28, 28, 28, 0) 100%
    );
  }
}