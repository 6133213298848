@import "theme";
@import "page_transition";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700;900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}
body {
  padding-bottom: 25vh;
}

.modal-backdrop.show {
  opacity: 0.85;
}

.light_mode {
  background: #fff;

  .bg-light {
    background: #fff !important;
  }

  .navbar {
    border-bottom: 1px solid #efefef;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  }
}
.dark_mode,
.modal-content {
  background: #1c1c1c;
  color: #efefef;

  .navbar {
    border-bottom: 1px solid darken(#1c1c1c, 10%);
    box-shadow: rgba(0, 0, 0, 1) 0px 0px 10px;
  }
  .navbar-nav {
    color: #ccc;
    .nav-link,
    .navbar-text {
      color: #ccc;

      &:hover {
        color: #efefef;
      }
      &.active {
        color: #fff;
      }
    }
  }

  .offcanvas,
  .form-select,
  .form-control,
  .navbar {
    background-color: lighten(#1c1c1c, 5%) !important;
    color: #efefef;
    border-color: darken(#1c1c1c, 10%);
  }
  .navbar {
    button {
      filter: invert(1);
    }
  }

  .rdt_Table *,
  .rdt_TableRow,
  .rdt_Pagination {
    color: #efefef;
    background: #1c1c1c;
    border-bottom-color: lighten(#1c1c1c, 10%);

    svg {
      filter: invert(1);
    }
  }

  .table {
    color: #efefef;
    border-color: lighten(#1c1c1c, 10%);
  }
  .btn-close {
    filter: invert(1);
  }
}

.navbar-brand {
  padding: 0;
  img {
    height: 50px;
  }
}

@media (max-width: 767px) {
  div.rdt_TableCol,
  div.rdt_TableCell {
    padding: 5px;
    flex-grow: 0;
  }
}
