.login {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 20px 30px;
    border-radius: 5px;
    background: darken(#fff, 10%);
    border: 1px solid 3fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
}

.dark_mode {
  .login-box {
    background: darken(#1c1c1c, 10%);
    border: 1px solid lighten(#1c1c1c, 10%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
}
